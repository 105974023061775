import { render, staticRenderFns } from "./Groups.vue?vue&type=template&id=4d44b9a3&scoped=true&"
import script from "./Groups.vue?vue&type=script&lang=js&"
export * from "./Groups.vue?vue&type=script&lang=js&"
import style0 from "./Groups.vue?vue&type=style&index=0&id=4d44b9a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d44b9a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
