var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1"},[_c('p',{staticClass:"ml-0 my-3 primary--text font-weight-bold"},[_vm._v("Secciones")]),_c('v-row',[_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","color":hover ? 'secondary' : 'background',"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'admin-groups' })}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"dark":hover}},[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',{class:{ 'white--text': hover }},[_vm._v(" Grupos ")])],1)],1)],1)]}}])})],1),_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","color":hover ? 'secondary' : 'background',"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'admin-classes' })}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"dark":hover}},[_vm._v("mdi-video")])],1),_c('v-list-item-content',{class:{ 'white--text': hover }},[_vm._v(" Clases ")])],1)],1)],1)]}}])})],1),_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","color":hover ? 'secondary' : 'background',"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'admin-tasks' })}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"dark":hover}},[_vm._v("mdi-clipboard-list-outline")])],1),_c('v-list-item-content',{class:{ 'white--text': hover }},[_vm._v(" Tareas ")])],1)],1)],1)]}}])})],1),_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","color":hover ? 'secondary' : 'background',"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'admin-exams' })}}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"dark":hover}},[_vm._v("mdi-clipboard-check")])],1),_c('v-list-item-content',{class:{ 'white--text': hover }},[_vm._v(" Examenes ")])],1)],1)],1)]}}])})],1)],1),_c('p',{staticClass:"ml-0 my-3 primary--text font-weight-bold"},[_vm._v("Criterios de evaluación")]),(_vm.criteria.length > 0)?_c('v-row',[_c('v-col',[_c('v-list',[_c('v-divider'),_vm._l((_vm.criteria),function(cr,index){return [_c('v-list-item',{key:cr.name,staticClass:"rounded-lg py-2"},[_c('v-list-item-avatar',{staticClass:"rounded-lg",attrs:{"color":"primary","tile":"","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check-circle")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Nombre ")]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(cr.name)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Numero de items ")]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(cr.number)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Valor por item ")]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s((cr.value/cr.number).toFixed(2))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Valor total ")]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(cr.value)+" ")])],1)],1),_c('v-divider',{key:index})]})],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }