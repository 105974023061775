var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.addNew($event)}}},[_vm._v(" Crear Examen ")])],1),(_vm.exams != null)?_c('v-row',[_c('v-col',{class:{'empty': _vm.exams.length === 0}},[(_vm.exams.length > 0)?_c('v-list',[_c('v-divider'),_vm._l((_vm.exams),function(exam,index){return [_c('v-list-item',{key:exam.id,staticClass:"rounded-lg py-2"},[_c('v-list-item-avatar',{staticClass:"rounded-lg",attrs:{"color":"primary","tile":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-document-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(exam.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(exam.description)+" ")])],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-auto mr-1",attrs:{"color":"secondary"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"font-size":"12px","width":"22px"}},[_vm._v(_vm._s(exam.questions.length))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Numero de preguntas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-auto ml-1",class:{ 'mx-1': exam.final },attrs:{"color":"secondary"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"font-size":"12px","width":"20px"}},[_vm._v(" "+_vm._s(Number.isInteger(exam.available_after / (( 1000 * 60 * 60 * 24 * 7 ))) ? exam.available_after / (( 1000 * 60 * 60 * 24 * 7 )) : (exam.available_after / (( 1000 * 60 * 60 * 24 * 7 ))).toFixed(1))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-start")])],1)]}}],null,true)},[_c('span',[_vm._v("Semana de liberación")])]),(exam.final)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-auto ml-1",attrs:{"color":"secondary"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Examen final")])])]}}],null,true)},[_c('span',[_vm._v("Exámen final")])]):_vm._e()],1)]),_c('v-list-item-action',{staticClass:"d-none d-md-block"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(exam.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('v-divider',{key:index})]})],2):_c('div',{staticClass:"font-weight-bold blue-grey--text text-center"},[_c('v-icon',{staticClass:"ma-auto font-weight-bold blue-grey--text",attrs:{"x-large":""}},[_vm._v("mdi-calendar-check-outline")]),_c('p',[_vm._v("No hay tareas disponibles")])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","scrollable":""},model:{value:(_vm.form_exams.open),callback:function ($$v) {_vm.$set(_vm.form_exams, "open", $$v)},expression:"form_exams.open"}},[_c('formExam',{attrs:{"exam":_vm.form_exams.exam},on:{"close":_vm.closeForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }